import { styled } from '@linaria/react';

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
`;

export const BackCover = styled.div`
  flex: 0 0 8in;
  width: 8in;
  height: 10in;
`;

export const FrontCover = styled.div`
  flex: 0 0 8in;
  width: 8in;
  height: 10in;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5in;
`;

export const CoverPhoto = styled.img`
  max-width: 4.4145in;
  max-height: 3.091425in;
  object-fit: cover;
`;

export const CoverTitle = styled.span`
  text-align: center;
  font-family: var(--font-serif);
  line-height: 130%;
  font-size: 0.458in;
  white-space: pre-line;
  word-break: break-word;
`;

export const CoverContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  flex-grow: 0;
  position: relative;
  padding: 0.4in 0;
`;

export const CoverTitleDecorator = styled.div`
  width: 1in;
  height: 1px;
  position: absolute;

  left: 50%;
  transform: translateX(-50%);

  &[data-position='top'] {
    top: 0;
  }

  &[data-position='bottom'] {
    bottom: 0;
  }
`;

export const CoverSubtitle = styled.span`
  text-align: center;
  font-family: var(--font-serif);
  font-size: 0.20355in;
`;

export const Spine = styled.div`
  height: 10in;
  position: relative;
  padding: 0.40765in 0;
`;

export const TransformedSpine = styled.div`
  position: absolute;
  transform: rotate(90deg);
  transform-origin: 0 0;
  display: flex;
  align-items: center;
  gap: 0.140125in;
  /* The height is 10in, minus the top/bottom padding */
  width: calc(10in - 0.40765in - 0.40765in);
`;

export const SideText = styled.div`
  font-family: var(--font-sans);
  font-size: 0.140125in;
  text-transform: uppercase;
  white-space: nowrap;
`;

export const SideTextSeparator = styled.hr`
  height: 0.1544in;
  flex: 0 0 1px;
`;
